<template>
  <div class="page-user-schoolinfo">
    <!-- 学校名称 -->
    <div class="school-box">
      <div class="school-name">
        <img
          :src="
            viewData.uploadList && viewData.uploadList.length > 0
              ? viewData.uploadList[0].url
              : logo
          "
          alt=""
          @error="errorImg"
        />
        <span>{{ viewData.Name }}</span>
      </div>
      <div class="welcome">我们招生啦！</div>
    </div>
    <div class="my-page">
      <div class="card-box">
        <div class="my-card">
          <div class="card-head">
            <p>幼儿园简介</p>
            <div>INTRODUCTION TO KINDERGARTEN</div>
          </div>
          <div class="card-content">
            {{ viewData.Desc }}
          </div>
          <div class="card-option">
            <div class="telephone"><i></i>{{ viewData.CellPhone }}</div>
            <div class="address"><i></i>{{ viewData.Address }}</div>
          </div>
        </div>
        <div class="my-card">
          <div class="card-head">
            <p>校园风采</p>
            <div>SCENERY OF KINDERGARTEN</div>
          </div>
          <div class="card-imgs">
            <template v-if="viewData.schoolPhotoList.length > 0">
              <van-image
                v-for="(item, i) in viewData.schoolPhotoList"
                :key="i"
                width="100"
                height="74"
                lazy-load
                :src="item.url"
                @click="
                  () => {
                    images = []
                    images.push(item.url)
                    imgShow = true
                  }
                "
                style="margin-right: 10px;margin-bottom: 10px;border-radius: 6px;overflow: hidden;display: inline-block;"
              />
            </template>
            <van-empty v-else description="暂无图片" />
          </div>
        </div>
        <div class="my-card">
          <div class="card-head">
            <p>校园荣誉</p>
            <div>HONOR OF KINDERGARTEN</div>
          </div>
          <div class="card-imgs">
            <template v-if="viewData.schoolHonorList.length > 0">
              <van-image
                v-for="(item, i) in viewData.schoolHonorList"
                :key="i"
                width="100"
                height="74"
                lazy-load
                :src="item.url"
                @click="
                  () => {
                    images = []
                    images.push(item.url)
                    imgShow = true
                  }
                "
                style="margin-right: 10px;margin-bottom: 10px;border-radius: 6px;overflow: hidden;display: inline-block;"
              />
            </template>
            <van-empty v-else description="暂无图片" />
          </div>
        </div>
        <!-- <div class="my-card">
          <div class="card-head">
            <p>招生信息</p>
            <div>ENROLLMENT INFORMATION</div>
          </div>
          <div class="card-enter">
            <div class="enter-title">
              <span>招生对象</span>
            </div>
            <div class="enter-content">
              <p>年满3周岁-6周岁的宝贝</p>
            </div>
          </div>
          <div class="card-enter">
            <div class="enter-title">
              <span>招生计划</span>
            </div>
            <div class="enter-content">
              <div class="enter-top">新生入园需准备以下材料：</div>
              <p>1. 宝贝照片、户口本户主及宝贝页的照片复印件；</p>
              <p>2. 儿童保健手册、儿童预防接种卡、体检报告单；</p>
              <p>
                3.
                请家长们携带以上材料，到江南春城小区二期内加盖“入园加速章”，并将相关资料交至报名处老师
              </p>
            </div>
          </div>
          <div class="card-enter">
            <div class="enter-title">
              <span>报名时间</span>
            </div>
            <div class="enter-content">
              <p>2021年5月30日-2021年9月30日</p>
            </div>
          </div>
          <div class="card-enter">
            <div class="enter-title">
              <span>报名咨询</span>
            </div>
            <div class="enter-content">
              <p>孙老师/19933842829</p>
            </div>
          </div>
        </div> -->
        <div v-if="viewData.isAdminRole" class="my-card">
          <div class="school-edit" @click="edit">编辑</div>
        </div>
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import { Button, Image, ImagePreview, Empty } from 'vant'
import pic1 from '@/assets/img/school-pic1.png'
import pic2 from '@/assets/img/school-pic2.png'
import award from '@/assets/img/school-award.png'
import logo from '@/assets/img/logo.png'

export default {
  components: {
    [Button.name]: Button,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Empty.name]: Empty
  },
  data () {
    return {
      userInfo: JSON.parse(window.localStorage.getItem('userInfo')),
      imgShow: false,
      images: [],
      pic1: pic1,
      pic2: pic2,
      award: award,
      logo: logo,
      viewData: {
        schoolPhotoList: [],
        schoolHonorList: []
      }
    }
  },
  mounted () {
    if (this.userInfo && this.userInfo.ReturnSchoolID) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.$axios
        .get('/api/School/Get', {
          id: this.userInfo.ReturnSchoolID
        })
        .then(res => {
          this.viewData = res.data
        })
    },
    edit () {
      this.$router.push({
        path: 'SchoolEdit',
        query: {
          id: this.viewData.ID
        }
      })
    },
    errorImg () {
      const img = event.srcElement
      img.src = this.logo
      img.onerror = null
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>

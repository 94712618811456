var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-user-schoolinfo"},[_c('div',{staticClass:"school-box"},[_c('div',{staticClass:"school-name"},[_c('img',{attrs:{"src":_vm.viewData.uploadList && _vm.viewData.uploadList.length > 0
            ? _vm.viewData.uploadList[0].url
            : _vm.logo,"alt":""},on:{"error":_vm.errorImg}}),_c('span',[_vm._v(_vm._s(_vm.viewData.Name))])]),_c('div',{staticClass:"welcome"},[_vm._v("我们招生啦！")])]),_c('div',{staticClass:"my-page"},[_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"my-card"},[_vm._m(0),_c('div',{staticClass:"card-content"},[_vm._v(" "+_vm._s(_vm.viewData.Desc)+" ")]),_c('div',{staticClass:"card-option"},[_c('div',{staticClass:"telephone"},[_c('i'),_vm._v(_vm._s(_vm.viewData.CellPhone))]),_c('div',{staticClass:"address"},[_c('i'),_vm._v(_vm._s(_vm.viewData.Address))])])]),_c('div',{staticClass:"my-card"},[_vm._m(1),_c('div',{staticClass:"card-imgs"},[(_vm.viewData.schoolPhotoList.length > 0)?_vm._l((_vm.viewData.schoolPhotoList),function(item,i){return _c('van-image',{key:i,staticStyle:{"margin-right":"10px","margin-bottom":"10px","border-radius":"6px","overflow":"hidden","display":"inline-block"},attrs:{"width":"100","height":"74","lazy-load":"","src":item.url},on:{"click":() => {
                  _vm.images = []
                  _vm.images.push(item.url)
                  _vm.imgShow = true
                }}})}):_c('van-empty',{attrs:{"description":"暂无图片"}})],2)]),_c('div',{staticClass:"my-card"},[_vm._m(2),_c('div',{staticClass:"card-imgs"},[(_vm.viewData.schoolHonorList.length > 0)?_vm._l((_vm.viewData.schoolHonorList),function(item,i){return _c('van-image',{key:i,staticStyle:{"margin-right":"10px","margin-bottom":"10px","border-radius":"6px","overflow":"hidden","display":"inline-block"},attrs:{"width":"100","height":"74","lazy-load":"","src":item.url},on:{"click":() => {
                  _vm.images = []
                  _vm.images.push(item.url)
                  _vm.imgShow = true
                }}})}):_c('van-empty',{attrs:{"description":"暂无图片"}})],2)]),(_vm.viewData.isAdminRole)?_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"school-edit",on:{"click":_vm.edit}},[_vm._v("编辑")])]):_vm._e()])]),_c('van-image-preview',{attrs:{"images":_vm.images},model:{value:(_vm.imgShow),callback:function ($$v) {_vm.imgShow=$$v},expression:"imgShow"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-head"},[_c('p',[_vm._v("幼儿园简介")]),_c('div',[_vm._v("INTRODUCTION TO KINDERGARTEN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-head"},[_c('p',[_vm._v("校园风采")]),_c('div',[_vm._v("SCENERY OF KINDERGARTEN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-head"},[_c('p',[_vm._v("校园荣誉")]),_c('div',[_vm._v("HONOR OF KINDERGARTEN")])])
}]

export { render, staticRenderFns }